import { apiMixins } from "./api";

const SUBJECT_ENDPOINT = "subject";

export const subjectMixins = {
  mixins: [apiMixins],
  methods: {
    async getAvailableSubjects({ showLoader = false } = {}) {
      return await this.callAPI(SUBJECT_ENDPOINT, {
        showLoader,
      });
    },
  },
};
