<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ["metadata-changed"],
  data() {
    return {
      metadata: this.item.metadata,
      newKey: null,
      newValue: null,
      isAdmin: this.$store.state.auth.userData.isAdmin,
    };
  },
  methods: {
    metadataAdded() {
      if (!this.isAddMetadataValid()) {
        return;
      }
      this.metadata.push({ key: this.newKey.trim(), value: this.newValue.trim() });
      this.newKey = "";
      this.newValue = "";
      this.$emit("metadata-changed", this.metadata);
    },
    metadataRemoved(itemToRemove) {
      this.metadata = this.metadata.filter((m) => m.key !== itemToRemove);
      this.$emit("metadata-changed", this.metadata);
    },
    isAddMetadataValid() {
      return this.newKey && this.newValue && !this.metadata.find(({ key }) => key === this.newKey);
    },
    amASteward() {
      return this.item && this.item.steward && this.item.steward.includesMe;
    },
    amAnOwner() {
      return this.item && this.item.owner && this.item.owner.includesMe;
    }
  },
  computed: {
    canAdd() {
      return (
        !this.item.suspended &&
        !this.item.trashRepository &&
        (this.$store.state.auth.userData.isAdmin ||
        this.amASteward ||
        this.amAnOwner)
      );
    },
    canDelete() {
      return (
        !this.item.suspended &&
        !this.item.trashRepository &&
        (this.$store.state.auth.userData.isAdmin ||
        this.item.steward.includesMe ||
        this.item.owner.includesMe)
      );
    },
  },
};
</script>

<template>
  <section class="metadata">
    <h5>{{ $t("metadata-heading") }}</h5>
    <section>
      <p>{{ $t("metadata-description-add") }}</p>
      <div class="metadata-add">
        <m-text-field
          id="add-key-text-field"
          outlined
          style="width:90%"
          v-model="newKey"
          maxlength="100"
          :disabled="!canAdd"
        >
          <m-floating-label for="add-key-text-field">{{
            $t("new-key-placeholder")
          }}</m-floating-label>
        </m-text-field>

        <m-text-field
          id="add-value-text-field"
          outlined
          style="width:90%"
          v-model="newValue"
          maxlength="100"
          :disabled="!canAdd"
        >
          <m-floating-label for="add-value-text-field">{{
            $t("new-value-placeholder")
          }}</m-floating-label>
        </m-text-field>
        <button class="primary" @click="metadataAdded" :disabled="!canAdd">
          {{ $t("add-metadata") }}
        </button>
      </div>

      <div class="metadata-current">
        <div
          class="metadata-item"
          v-for="metadataItem in metadata"
          :key="metadataItem.key"
        >
        <div>
          <div style="font-weight:bold"><pre>{{ metadataItem.key }}</pre></div>
          <div><pre>{{ metadataItem.value }}</pre></div>
        </div>
          <div style="padding-top:1.8rem">
            <m-icon
              class="material-icons-outlined delete-metadata"
              icon="highlight_off"
              @click="metadataRemoved(metadataItem.key)"
              v-if="canDelete"
            />
            <m-icon
              class="material-icons-outlined delete-metadata-inactive md-inactive"
              icon="highlight_off"
              v-if="!canDelete"
            />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<style lang="scss" scoped>
h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.metadata-add {
  display: grid;
  grid-template-columns: minmax(100px, 400px) minmax(100px, 400px) minmax(
      50px,
      100px
    );
  margin-top: 1rem;

  input[type="text"] {
    background: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    color: black;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }
}

.metadata-current {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 1rem;

  > .metadata-item {
    display: grid;
    grid-template-columns: minmax(100px, 835px) minmax(
        50px,
        100px
      );
    margin-top: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 0.5rem;

    pre {
      font-size: 0.8rem;
    }
  }
}

.delete-metadata {
  cursor: pointer;
}

.delete-metadata-inactive {
  color: #e5e5e5;
}

.metadata {
  > h5 {
    margin: 2rem 1rem 0;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    background: white;
    padding: 1.5rem;
  }

  .warning {
    color: #ef0000;
  }
}

.buttons {
  margin-top: 1rem;
}

button {
  background: transparent;
  border: 1px solid #1275cc;
  color: #1275cc;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;
  text-align: center;

  &.primary {
    background: #1275cc;
    border-color: transparent;
    color: white;
  }

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}
</style>
